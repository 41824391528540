import { COLORS } from '../tools/colors.js';

const aboutContainer = {
  padding: '12vw 12vw',
  backgroundColor: COLORS.darkModeDarkest
};

const aboutButton = {
  cursor: 'pointer',
};

const mainText = {
  marginTop: '5vw',
  color: COLORS.darkMainText,
  fontFamily: 'Source Sans Pro',
  fontSize: '5.5vw'
};

const clanDefinition = {
};

const clanText = {
  color: COLORS.darkShade1,
  fontWeight: 'bold',
  fontFamily: 'Source Sans Pro',
  fontSize: '5.5vw'
};

const styles = {
  aboutContainer: aboutContainer,
  aboutButton: aboutButton,
  clanDefinition: clanDefinition,
  clanText: clanText,
  mainText: mainText
};

export default styles;
