import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { initializeApp } from "firebase/app"
import HomePage from '../Home/Home.js';
import AboutPage from '../About/About.js';
import PrivacyPage from '../Privacy/Privacy.js';
import UserAgreementPage from '../UserAgreement/UserAgreement.js';
import Dashboard from '../Dashboard/Dashboard.js';
import DataDeletion from '../DataDeletion/DataDeletion.js';

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyBp2i84JrxjMilj5eX9lu03MOeMRRbyT1k",
    authDomain: "motoclan-website.firebaseapp.com",
    projectId: "motoclan-website",
    storageBucket: "motoclan-website.appspot.com",
    messagingSenderId: "498945609305",
    appId: "1:498945609305:web:bb6aeb4a3e5858d7142b48",
    measurementId: "G-MBFTEW1WY7"
  };

  // Initialize Firebase
  initializeApp(firebaseConfig);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/about">
          <AboutPage />
        </Route>
        <Route exact path="/privacy">
          <PrivacyPage />
        </Route>
        <Route exact path="/user-agreement">
          <UserAgreementPage />
        </Route>
        <Route exact path="/brave-new-world">
          <Dashboard />
        </Route>
        <Route exact path="/data-deletion">
          <DataDeletion />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
