import React, { useState } from "react";
import { Link } from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { collection, addDoc, getFirestore } from "firebase/firestore";
import googlePlayLogo from '../assets/googlePlayLogo.png';
import appStoreLogo from '../assets/appStoreLogo.png';
import discordLogo from '../assets/discordLogo.png';
import {stylesDesktop, desktopContentStyle} from './FooterStylesDesktop.js';
import {stylesMobile, mobileContentStyle} from './FooterStylesMobile.js';
// import './Footer.css';

const Footer = (props) => {
  // console.log(props.isMobile);

  if(props.isMobile)
    return MobileVersion();
  else
    return DesktopVersion();
}

const addMeClicked = async (email, setEmailSubmitted) => {
  console.log('add me as tester clicked');
  console.log('inspecting email', email);
  const db = getFirestore();
  try {
    const docRef = await addDoc(collection(db, 'android-tester-emails'), {
      emailAddress: email,
      retrieved: false
    });

    const mailRef = await addDoc(collection(db, 'mail'), {
      to: "luciferhilde@gmail.com",
      message: {
        subject: "a new Android tester has just signed up",
        text: email
      },
    });

    setEmailSubmitted(true);
    console.log("Document written with ID: ", docRef.id);
    console.log("Document written with ID: ", mailRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

const MobileVersion = () => {
  const [email, setEmail] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const contentStyle = mobileContentStyle;

  console.log('emailSubmitted', emailSubmitted);
  return (
    <div style = {stylesMobile.footer}>
      <div style = {stylesMobile.footerTitle}>Join Motoclan!</div>
      <div style = {stylesMobile.storesLogoWrap}>
        <Link
          to = {{ pathname: "https://testflight.apple.com/join/rz5qEmxA"}}
          target="_blank"
        >
          <img
            onClick = {() => {
              console.log('AppStoreLogo pressed');
            }}
            style = {stylesMobile.storeLogo}
            src = {appStoreLogo}
            alt = "appStoreLogo"
          />
        </Link>
        <Popup
          trigger = {
            <img
              onClick = {() => { console.log('googlePlayLogo pressed') }}
              style = {stylesMobile.storeLogo}
              src = {googlePlayLogo}
              alt = "googlePlayLogo"
            />
          }
          modal
          {...{ contentStyle}}
        >
          {close => (
            <div style = {stylesMobile.modalWrap}>
              {emailSubmitted ?
                <div>
                  <div style = {stylesMobile.modalNotifMessage}>
                    Thank your signing up as a tester
                  </div>
                  <div style = {stylesMobile.modalTitleDescription}>
                    We will notify you when our app is ready to download
                  </div>
                  <div
                    onClick = {() => {
                      close();
                      setEmailSubmitted(false);
                    }}
                    style = {stylesMobile.modalAddButton}
                  >
                    OK
                  </div>
                </div>
              :
                <div>
                  <div style = {stylesMobile.closeButton} onClick={close}>
                    &times;
                  </div>

                  <div style = {stylesMobile.modalTitle}>
                    Your email address
                  </div>
                  <div style = {stylesMobile.modalTitleDescription}>
                    linked to your Google Play
                  </div>

                  <form>
                    <input
                      type="email"
                      name="email"
                      style = {stylesMobile.modalInput}
                      required
                      value = {email}
                      onChange={e => setEmail(e.target.value)}
                    />

                    <div
                      onClick = {() => addMeClicked(email, setEmailSubmitted)}
                      style = {stylesMobile.modalAddButton}
                    >
                      Add me as tester
                    </div>
                  </form>
                </div>
              }
            </div>
          )}
        </Popup>
      </div>
      <div style = {stylesMobile.connect}>
        Share the love on
      </div>
      <Link
        to = {{ pathname: "https://discord.gg/UBuPyt377r"}}
        target="_blank"
      >
        <img
          onClick = {() => { console.log('discordLogo pressed') }}
          style = {stylesMobile.discordLogo}
          src = {discordLogo}
          alt = "discordLogo"
        />
      </Link>
      <div style = {stylesMobile.copyRight}>
        Copyright © 2024 Clan LLC - All rights reserved
      </div>
    </div>
  )
}

const DesktopVersion = () => {
  const [email, setEmail] = useState("");
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const contentStyle = desktopContentStyle;
  return (
    <div>
      <div style = {stylesDesktop.footerWrap}>
        <div style = {stylesDesktop.footerTitle}>
          Join Motoclan!
        </div>
        <div style = {stylesDesktop.storesLogoWrap}>
          <Link
            to = {{ pathname: "https://testflight.apple.com/join/rz5qEmxA"}}
            target="_blank"
          >
            <img
              onClick = {() => { console.log('appStoreLogo pressed') }}
              style = {stylesDesktop.storeLogo}
              src = {appStoreLogo}
              alt = "appStoreLogo"
            />
          </Link>
          <Popup
            trigger = {
                <img
                  onClick = {() => { console.log('googlePlayLogo pressed') }}
                  style = {stylesDesktop.storeLogo}
                  src = {googlePlayLogo}
                  alt = "googlePlayLogo"
                />
              }
            modal
            {...{ contentStyle}}
          >
            {close => (
              <div style = {stylesMobile.modalWrap}>
                {emailSubmitted ?
                  <div>
                    <div style = {stylesMobile.modalNotifMessage}>
                      Thank your signing up as a tester
                    </div>
                    <div style = {stylesMobile.modalTitleDescription}>
                      We will notify you when our app is ready to download
                    </div>
                    <div
                      onClick = {() => {
                        close();
                        setEmailSubmitted(false);
                      }}
                      style = {stylesMobile.modalAddButton}
                    >
                      OK
                    </div>
                  </div>
                :
                  <div>
                    <div style = {stylesMobile.closeButton} onClick={close}>
                      &times;
                    </div>

                    <div style = {stylesMobile.modalTitle}>
                      Your email address
                    </div>
                    <div style = {stylesMobile.modalTitleDescription}>
                      linked to your Google Play
                    </div>

                    <form>
                      <input
                        type="email"
                        name="email"
                        style = {stylesMobile.modalInput}
                        required
                        value = {email}
                        onChange={e => setEmail(e.target.value)}
                      />

                      <div
                        onClick = {() => addMeClicked(email, setEmailSubmitted)}
                        style = {stylesMobile.modalAddButton}
                      >
                        Add me as tester
                      </div>
                    </form>
                  </div>
                }
              </div>
            )}
          </Popup>
        </div>
      </div>

      <div style = {stylesDesktop.copyRight}>
        <div style = {stylesDesktop.copyRightText}>Copyright © 2024 Clan LLC - All rights reserved</div>
        <Link
          to = {{ pathname: "https://discord.gg/UBuPyt377r"}}
          target="_blank"
        >
          <img
            onClick = {() => { console.log('discordLogo pressed') }}
            style = {stylesDesktop.discordLogo}
            src = {discordLogo}
            alt = "discordLogo"
          />
        </Link>
      </div>
    </div>
  );
}

export default Footer;
