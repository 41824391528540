import { COLORS } from '../tools/colors.js';

const wallpaper = {
  width: '100%',
  display: 'block'
};

const logoWrap = {
  position: 'absolute',
  top: '2vw',
  left: '29.5vw'
}

const clanLogo = {
  width: '100px',
  height: '100%',
  cursor: 'pointer',
};

const intro = {
  position: 'absolute',
  top: '15vw',
  right: '13vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start'
};

const introText = {
  color: COLORS.darkMainText,
  fontSize: '3vw',
  fontWeight: 'bold',
  fontFamily: 'Source Sans Pro'
};

const button = {
  backgroundColor: COLORS.darkMain,
  color: COLORS.darkMainText,
  margin: '2vw 0vw 0vw 0vw',
  padding: '1vw 1.5vw',
  borderRadius: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  fontWeight: '500',
  fontSize: '2vw'
};

const featureDarkest = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: COLORS.darkModeDarkest
};

const featureDark = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: COLORS.darkModeBackground
};

const featureImage = {
  maxWidth: '80%',
  borderRadius: '10px',
}

const paddingForLeft = {
  padding: '10vw 4vw 10vw 18vw',
}

const paddingForRight = {
  padding: '10vw 18vw 10vw 4vw',
}

const featureImageWrap = {
  flex: 1,
  textAlign: 'center'
}

const featureTextWrap = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}

const feature_title = {
  color: COLORS.darkMainText,
  margin: '0vw 0vw 1vw 0vw',
  fontSize: '3.5vw'
}

const feature_text = {
  color: COLORS.darkSecondaryText,
  fontSize: '2.5vw'
}

const feature_text_second = {
  color: COLORS.darkSecondaryText,
  fontSize: '2vw',
  marginTop: '1vw'
}

const styles = {
  wallpaper: wallpaper,
  logoWrap: logoWrap,
  clanLogo: clanLogo,
  intro: intro,
  introText: introText,
  button: button,
  featureTextWrap: featureTextWrap,
  featureDarkest: featureDarkest,
  featureDark: featureDark,
  featureImage: featureImage,
  featureImageWrap: featureImageWrap,
  feature_title: feature_title,
  feature_text: feature_text,
  feature_text_second: feature_text_second,
  paddingForLeft: paddingForLeft,
  paddingForRight: paddingForRight
};

export default styles;
