import { collection, doc, getDocs, getFirestore, where, updateDoc, query} from "firebase/firestore";

const Dashboard = props => {
  return(
    <div style ={{ flex: 1 }}>
      <button onClick = {() => retrieveUserIds()}> retrieve wind catchers </button>
      <button onClick = {() => markEmailsRetrieved()}> mark them retrieved </button>
    </div>
  );
}

const retrieveUserIds = async () => {
  const db = getFirestore();
  try {
    const collectionRef = collection(db, 'android-tester-emails')
    const emailQuery = query(collectionRef, where("retrieved", "==", false));

    const querySnapshot = await getDocs(emailQuery);
    
    let listOfEmails = [];
    querySnapshot.forEach((doc) => {
      listOfEmails.push(doc.data()['emailAddress']);
    })

    console.log('list of email addresses', listOfEmails);
  } catch (e) {
    console.error("Error getting documents: ", e);
  }
}

const markEmailsRetrieved = async () => {
  const db = getFirestore();
  try {
    const collectionRef = collection(db, 'android-tester-emails');
    const emailQuery = query(collectionRef, where("retrieved", "==", false));

    const querySnapshot = await getDocs(emailQuery);
    querySnapshot.forEach((document) => {
      updateDoc(doc(collectionRef, document.id), {
        retrieved: true
      })
    });
    
    console.log('Successfully marked the emails retrieved');
  } catch (e) {
    console.error("Error updating documents: ", e);
  }
}

export default Dashboard
