import { COLORS } from '../tools/colors.js';

const DataDeletion = props => {
  return(
    <div style ={{ 
      textAlign: 'center',
      padding: '10vw 15vw',
      backgroundColor: COLORS.darkModeBackground
    }}>
      <div style ={{ 
        color: COLORS.darkMain,
        fontSize: 36,
        marginBottom: '20px',
        fontWeight: 'bold'
      }}>
        Data deletion instruction
      </div>
      <div style ={{ 
        color: COLORS.darkMainText,
        fontSize: 32,
        textAlign: 'start'
      }}>
        To make a data deletion request, please go to your profile section on Motoclan app.
        Then, when you press on the settings button, "Request data deletion" button will appear.
        Once you hit that, we would be notified and a proper action would be taken on our end.
      </div>

      <div style ={{ 
        color: COLORS.darkMainText,
        fontSize: 32,
        textAlign: 'start',
        marginTop: '10px'
      }}>
        Warning! You might lose your access to your account. If you have any questions, please email us at scortumee@gmail.com
      </div>
    </div>
  );
}

export default DataDeletion;