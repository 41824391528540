import { COLORS } from '../tools/colors.js';

const footerWrap = {
  textAlign: 'center',
  padding: '10vw 0vw',
  backgroundColor: COLORS.darkModeBackground
}

const footerTitle = {
  color: COLORS.darkCallToAction,
  fontSize: '4vw'
}

const storesLogoWrap = {
  margin: '2vw 0vw 0vw 0vw',
}

const storeLogo = {
  margin: '1vw',
  height: '4vw',
  cursor: 'pointer',
}

const copyRight = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'end',
  padding: '0vw 5vw 3vw 5vw',
  backgroundColor: COLORS.darkModeBackground,
}

const copyRightText = {
  color: COLORS.darkSecondaryText
}

const discordLogo = {
  cursor: 'pointer',
  width: '3.5vw'
}

export const stylesDesktop = {
  footerWrap: footerWrap,
  footerTitle: footerTitle,
  storesLogoWrap: storesLogoWrap,
  storeLogo: storeLogo,
  copyRight: copyRight,
  copyRightText: copyRightText,
  discordLogo: discordLogo
};

// below is to override modal default styles
export const desktopContentStyle = {
  width: '350px',
  background: COLORS.darkModeDarkest,
  padding: '20px',
  border: 'none',
  borderRadius: '10px'
}
