import React, { useRef } from "react";
import {
  Link
} from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import stylesMobile from '../Home/HomeStylesMobile.js';
import stylesAboutMobile from './AboutStylesMobile.js';
import stylesDesktop from '../Home/HomeStylesDesktop.js';
import mobileWallpaper from '../assets/homepageWallpaperMobile.png';
import desktopWallpaper from '../assets/homepageWallpaperDesktop.png';
import Footer from '../components/Footer.js';
import clanLogo from '../assets/clanLogo.png';

const AboutPage = props => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const firstFeatureRef = useRef(null);

  return(
    <div style ={{ flex: 1 }}>
      {
        isMobile &&
        MobileVersion(firstFeatureRef)
      }
      {
        isDesktop &&
        DesktopVersion(firstFeatureRef)
      }
    </div>
  );
}

const DesktopVersion = (firstFeatureRef) => {
  return (
    <div>
      <div style = {stylesDesktop.logoWrap}>
        <img
          src = {clanLogo}
          style = {stylesDesktop.clanLogo}
          alt = "clanLogo"
        />
      </div>

      <img
        style = {stylesDesktop.wallpaper}
        src = {desktopWallpaper}
        alt = "desktopWallpaper"
      />

      <Footer
        isMobile = {false}
      />
    </div>
  )
}

const MobileVersion = (firstFeatureRef) => {
  return (
    <div>
      <div style = {stylesMobile.header}>
        <Link to="/">
          <div style = {stylesMobile.logoWrap}>
            <img
              src = {clanLogo}
              style = {stylesMobile.clanLogo}
              alt = "clanLogo"
            />
          </div>
        </Link>
        <Link to="/about">
          <div style = {stylesMobile.aboutButton}>
            About us
          </div>
        </Link>
        <img
          style = {stylesMobile.mobileWallpaper}
          src = {mobileWallpaper}
          alt = "mobileWallpaper"
        />
      </div>

      <div style = {stylesAboutMobile.aboutContainer}>
        <div style = {stylesAboutMobile.clanDefiniton}>
          <span style = {stylesAboutMobile.clanText}>Clan(noun) </span>
          <span style = {stylesAboutMobile.mainText}>- a group of people with a strong common interest.</span>
        </div>
        <div style = {stylesAboutMobile.mainText}>
          Since the dawn of time, we hunted, fought, and lived as a group. The feeling of belonging to a certain group a.k.a Clan is an inseparable part of our life.
        </div>
        <div style = {stylesAboutMobile.mainText}>
          However, today's social media-infused world does not really satisfy that basic need. So we offer you our new platform Motoclan as a gift to your mental health.
        </div>
        <div style = {stylesAboutMobile.mainText}>
          Feeling a little down? Join our Clan to ride with others.
        </div>
        <div style = {stylesAboutMobile.mainText}>
          Riding by yourself is fun. But what is more enjoyable than riding with like-minded individuals and challenge yourself.
        </div>
      </div>

      <Footer
        isMobile = {true}
      />
    </div>
  );
}

export default AboutPage;
