import { COLORS } from '../tools/colors.js';

const footer = {
  padding: '12vw 12vw 8vw 12vw',
  backgroundColor: COLORS.darkModeBackground,
  textAlign: 'center',
}

const footerTitle = {
  color: COLORS.darkCallToAction,
  fontSize: '7vw'
}

const storesLogoWrap = {
  margin: '5vw 0vw 5vw 0vw',
  display: 'flex',
  justifyContent: 'space-between',
}

const storeLogo = {
  height: '11vw',
  cursor: 'pointer',
}

const connect = {
  margin: '10vw 0vw 0vw 0vw',
  color: COLORS.darkMainText,
  fontSize: '6vw'
}

const discordLogo = {
  cursor: 'pointer',
  width: '10vw',
  margin: '2vw 0vw 5vw 0vw'
}

const copyRight = {
  color: COLORS.darkSecondaryText,
  fontSize: '3.5vw'
}

const modalWrap = {
  display: 'flex',
  flexDirection: 'column',
}

const modalTitle = {
  color: COLORS.darkSecondaryText,
  fontSize: '20px'
}

const modalTitleDescription = {
  color: COLORS.darkSecondaryText,
  fontSize: '12px'
}

const modalInput = {
  backgroundColor: COLORS.darkModeDarkest,
  border: `1px solid ${COLORS.darkModeInactive}`,
  borderRadius: '10px',
  color: COLORS.darkMainText,
  marginTop: '10px',
  padding: '5px 5px 5px 10px',
  fontSize: '16px',
  width: '100%',
  boxSizing: 'border-box'
}

const modalAddButton = {
  backgroundColor: COLORS.darkCallToAction,
  color: COLORS.darkMainText,
  marginTop: '20px',
  padding: '10px',
  borderRadius: '10px',
  textAlign: 'center',
  cursor: 'pointer',
  fontWeight: '500',
  fontSize: '18px'
};

const modalNotifMessage = {
  color: COLORS.darkMain,
  fontSize: '20px',
  marginBottom: '10px'
}

const closeButton = {
  cursor: 'pointer',
  position: 'absolute',
  display: 'block',
  padding: '0px 5px 4px 5px',
  lineHeight: '20px',
  right: '-10px',
  top: '-10px',
  fontSize: '24px',
  background: COLORS.darkModeDarkest,
  borderRadius: '18px',
  color: COLORS.darkMainText,
  border: `1px solid ${COLORS.darkModeBackground}`
}

export const stylesMobile = {
  footer: footer,
  footerTitle: footerTitle,
  storesLogoWrap: storesLogoWrap,
  storeLogo: storeLogo,
  connect: connect,
  discordLogo: discordLogo,
  copyRight: copyRight,
  modalWrap: modalWrap,
  modalTitle: modalTitle,
  modalTitleDescription: modalTitleDescription,
  modalInput: modalInput,
  modalAddButton: modalAddButton,
  modalNotifMessage: modalNotifMessage,
  closeButton: closeButton
};

// below is to override modal default styles
export const mobileContentStyle = {
  width: '75%',
  background: COLORS.darkModeDarkest,
  padding: '20px',
  border: 'none',
  borderRadius: '10px'
}
