import { COLORS } from '../tools/colors.js';

const background = {
  width: '100%',
  height: '100%',
};

const header = {
  position: 'relative'
};

const button = {
  backgroundColor: COLORS.darkMain,
  color: COLORS.darkMainText,
  margin: '8vw 0vw 0vw 0vw',
  padding: '4vw 3vw',
  borderRadius: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  fontWeight: '500',
  fontSize: '5vw'
};

const aboutButton = {
  position: 'absolute',
  bottom: '5vw',
  right: '5vw',
  zIndex: 1,
  color: COLORS.darkMain,
  fontSize: '6vw',
  fontWeight: 'bold',
  fontFamily: 'Source Sans Pro'
};

const clanLogo = {
  width: '100px',
  height: '100%',
  cursor: 'pointer',
};

const feature1 = {
  padding: '12vw 12vw',
  backgroundColor: COLORS.darkModeDarkest,
  textAlign: 'center',
};

const feature2 = {
  padding: '12vw 12vw',
  backgroundColor: COLORS.darkModeBackground,
  textAlign: 'center',
};

const feature3 = {
  padding: '12vw 12vw',
  backgroundColor: COLORS.darkModeDarkest,
  textAlign: 'center',
};

const feature_title = {
  color: COLORS.darkMainText,
  margin: '5vw 0vw 2vw 0vw',
  fontSize: '10vw'
};

const feature_text = {
  color: COLORS.darkSecondaryText,
  fontSize: '6vw'
};

const feature_text_second = {
  color: COLORS.darkSecondaryText,
  fontSize: '5vw',
  marginTop: '3vw'
};

const feature_image = {
  position: 'relative',
  width: '100%',
  display: 'block',
  borderRadius: '10px',
  zIndex: 0
};

const intro = {
  backgroundColor: COLORS.darkModeBackground,
  padding: '12vw 12vw'
};

const introText = {
  color: COLORS.darkMainText,
  fontSize: '8vw',
  fontWeight: 'bold',
  fontFamily: 'Source Sans Pro'
};

const mobileWallpaper = {
  position: 'relative',
  width: '100%',
  display: 'block',
  zIndex: 0
};

const logoWrap = {
  position: 'absolute',
  top: '5vw',
  left: '10vw',
  zIndex: 1,
}

const styles = {
  background: background,
  button: button,
  header: header,
  aboutButton: aboutButton,
  clanLogo: clanLogo,
  feature1: feature1,
  feature2: feature2,
  feature3: feature3,
  feature_title: feature_title,
  feature_text: feature_text,
  feature_text_second: feature_text_second,
  feature_image: feature_image,
  intro: intro,
  introText: introText,
  logoWrap: logoWrap,
  mobileWallpaper: mobileWallpaper
};

export default styles;
