import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import stylesMobile from './HomeStylesMobile.js';
import stylesDesktop from './HomeStylesDesktop.js';
import mobileWallpaper from '../assets/homepageWallpaperMobile.png';
import desktopWallpaper from '../assets/homepageWallpaperDesktop.png';
import feature1Animated from '../assets/feature1.gif';
import feature2Animated from '../assets/feature2.gif';
import feature3Animated from '../assets/feature3.gif';
import Footer from '../components/Footer.js';
import clanLogo from '../assets/clanLogo.png';

const HomePage = props => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const firstFeatureRef = useRef(null);

  return(
    <div style ={{ flex: 1 }}>
      {
        isMobile &&
        MobileVersion(firstFeatureRef)
      }
      {
        isDesktop &&
        DesktopVersion(firstFeatureRef)
      }
    </div>
  );
}

const DesktopVersion = (firstFeatureRef) => {
  return (
    <div>
      <div style = {stylesDesktop.logoWrap}>
        <img
          src = {clanLogo}
          style = {stylesDesktop.clanLogo}
          alt = "clanLogo"
        />
      </div>

      <div style = {stylesDesktop.intro}>
        <div style = {stylesDesktop.introText}>Your way or</div>
        <div style = {stylesDesktop.introText}>Highway</div>
        <div
          onClick = {() => explorePressed(firstFeatureRef)}
          style = {stylesDesktop.button}
        >
          Explore
        </div>
      </div>

      <img
        style = {stylesDesktop.wallpaper}
        src = {desktopWallpaper}
        alt = "desktopWallpaper"
      />

      <div ref = {firstFeatureRef} style = {stylesDesktop.featureDarkest}>
        <div style = {{
          ...stylesDesktop.featureImageWrap,
          ...stylesDesktop.paddingForLeft
        }}>
          <img
            style = {stylesDesktop.featureImage}
            src = {feature1Animated}
            alt = "feature1Animated"
          />
        </div>

        <div style = {{
          ...stylesDesktop.featureTextWrap,
          ...stylesDesktop.paddingForRight
        }}>
          <div style = {stylesDesktop.feature_title}>Built For You</div>
          <div style = {stylesDesktop.feature_text}>Simply tap and drag to customize your route</div>
        </div>
      </div>

      <div style = {stylesDesktop.featureDark}>
        <div style = {{
          ...stylesDesktop.featureTextWrap,
          ...stylesDesktop.paddingForLeft
        }}>
          <div style = {stylesDesktop.feature_title}>Enjoy World Class Navigation</div>
          <div style = {stylesDesktop.feature_text}>Trusted by Tesla, Mapbox provides a seamless experience</div>
        </div>

        <div style = {{
          ...stylesDesktop.featureImageWrap,
          ...stylesDesktop.paddingForRight
        }}>
          <img
            style = {stylesDesktop.featureImage}
            src = {feature2Animated}
            alt = "feature2Animated"
          />
        </div>
      </div>

      <div style = {stylesDesktop.featureDarkest}>
        <div style = {{
          ...stylesDesktop.featureImageWrap,
          ...stylesDesktop.paddingForLeft
        }}>
          <img
            style = {stylesDesktop.featureImage}
            src = {feature3Animated}
            alt = "feature3Animated"
          />
        </div>

        <div style = {{
          ...stylesDesktop.featureTextWrap,
          ...stylesDesktop.paddingForRight
        }}>
          <div style = {stylesDesktop.feature_title}>Never Lose A Route</div>
          <div style = {stylesDesktop.feature_text}>We save your progress automatically</div>
          <div style = {stylesDesktop.feature_text_second}>Unlike other Maps, you’ll always pick up where you left off</div>
        </div>
      </div>

      <Footer
        isMobile = {false}
      />
    </div>
  )
}

const MobileVersion = (firstFeatureRef) => {
  return (
    <div>
      <div style = {stylesMobile.header}>
        <Link to="/">
          <div style = {stylesMobile.logoWrap}>
            <img
              src = {clanLogo}
              style = {stylesMobile.clanLogo}
              alt = "clanLogo"
            />
          </div>
        </Link>
        {/* 
          <Link to="/about">
            <div
              style = {stylesMobile.aboutButton}
            >
              About us
            </div>
          </Link>
        */}
        <img
          style = {stylesMobile.mobileWallpaper}
          src = {mobileWallpaper}
          alt = "mobileWallpaper"
        />
      </div>

      <div style = {stylesMobile.intro}>
        <div style = {stylesMobile.introText}>Your way or Highway</div>
        <div
          onClick = {() => explorePressed(firstFeatureRef)}
          style = {stylesMobile.button}
        >
          Explore
        </div>
      </div>

      <div ref = {firstFeatureRef} style = {stylesMobile.feature1}>
        <img
          style = {stylesMobile.feature_image}
          src = {feature1Animated}
          alt = "feature1Animated"
        />
        <div style = {stylesMobile.feature_title}>Built For You</div>
        <div style = {stylesMobile.feature_text}>Simply tap and drag to customize your route</div>
      </div>

      <div style = {stylesMobile.feature2}>
        <img
          style = {stylesMobile.feature_image}
          src = {feature2Animated}
          alt = "feature2Animated"
        />
        <div style = {stylesMobile.feature_title}>Enjoy World Class Navigation</div>
        <div style = {stylesMobile.feature_text}>Trusted by Tesla, Mapbox provides a seamless experience</div>
      </div>

      <div style = {stylesMobile.feature3}>
        <img
          style = {stylesMobile.feature_image}
          src = {feature3Animated}
          alt = "feature3Animated"
        />
        <div style = {stylesMobile.feature_title}>Never Lose A Route</div>
        <div style = {stylesMobile.feature_text}>We save your progress automatically</div>
        <div style = {stylesMobile.feature_text_second}>Unlike other Maps, you’ll always pick up where you left off</div>
      </div>

      <Footer
        isMobile = {true}
      />
    </div>
  )
}

const explorePressed = (firstFeatureRef) => {
  let intViewportHeight = window.innerHeight;
  let firstFeatureLocation = firstFeatureRef.current.getBoundingClientRect();

  let heightOfFirstFeature = firstFeatureLocation.height;
  let firstFeatureLocFromTop = firstFeatureLocation.top + window.scrollY

  window.scrollTo({
    top: firstFeatureLocFromTop - (intViewportHeight - heightOfFirstFeature)/2,
    behavior: 'smooth'
  })
}

export default HomePage;
