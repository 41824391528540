export const COLORS = {
  darkModeBackground: '#424850',
  darkModeDarkest: '#2B2E2F',
  darkMainText: 'white',
  darkModeInactive: '#6B7687',
  darkSecondaryText: '#A3A3A3',
  darkMain: '#2ABEED',
  darkCallToAction: '#69BB5B',
  darkShade1: '#74EE15'
}
